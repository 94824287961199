import { handleActions } from 'redux-actions'
import actions from './action'
import photoActions from '../Photo/action'

const initialState = {
  themes: null,
  theme: null,
  productTypes: null
}

export default handleActions(
  {
    [actions.getMetaSuccess]: (state, { payload }) => {
      return { ...state, ...payload }
    },
    [actions.select]: (state, { payload }) => {
      return { ...state, theme: payload }
    },
    [photoActions.generateSuccess]: state => {
      return { ...state, theme: null }
    }
  },
  initialState
)
