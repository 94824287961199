import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'
import { pipe } from 'ramda'
import { message } from 'antd'

const PopupMessage = ({ msg }) => {
  const [messageApi, contextHolder] = message.useMessage()
  useEffect(() => {
    if (msg) {
      const msgConfig = {
        key: 'message',
        type: msg.type,
        content: msg.content,
        style: {
          marginTop: '3vh'
        }
      }
      messageApi.open(msgConfig)
    }
    return () => {
      message.destroy('message')
    }
  }, [msg])
  return <>{contextHolder}</>
}

const enhancers = pipe(
  memo,
  connect(state => ({
    msg: state.general.message
  }))
)

export default enhancers(PopupMessage)
