import React, { memo } from 'react'
import { connect } from 'react-redux'
import { pipe } from 'ramda'
import cartActions from '../../Modules/Cart/action'
import { Col, Row, Button, Modal } from 'antd'
import { ImageItem } from '../Shared'

const Products = ({ isOpen, close, select, productTypes, shop }) => {
  return (
    <Modal
      open={isOpen}
      onCancel={() => close()}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      centered
    >
      <Row style={{ marginTop: '20px' }}>
        {productTypes.map(p => {
          return (
            <Col key={p.id} xl={8} lg={8} mg={12} sm={12} xs={12}>
              <div
                style={{
                  width: '100%',
                  paddingTop: '240%',
                  position: 'relative'
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    position: 'absolute',
                    top: '0',
                    left: '0'
                  }}
                >
                  <div
                    style={{
                      margin: 'auto',
                      width: 'calc(100% - 2px)',
                      height: 'calc(100% - 180px)',
                      border: '1px solid black',
                      textAlign: 'center'
                    }}
                  >
                    <ImageItem
                      images={[
                        { url: p.baseImageUri },
                        { url: p.coverImageUri }
                      ]}
                    />
                    <Button
                      type="primary"
                      htmlType="submit"
                      size={'large'}
                      style={{
                        backgroundColor: '#bf710e',
                        color: 'white',
                        fontWeight: 'bold',
                        borderRadius: '0',
                        position: 'absolute',
                        bottom: '20px',
                        zIndex: '5',
                        boxShadow:
                          '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        padding: '3px 12px',
                        height: 'fit-content',
                        width: '90%'
                      }}
                      onClick={() => {
                        select(p.id)
                        close()
                      }}
                    >
                      Select
                    </Button>
                  </div>
                  <div style={{ padding: '5px' }}>{p.displayName}</div>
                  <div
                    style={{
                      padding: '5px',
                      fontWeight: 'bold',
                      position: 'absolute',
                      bottom: '52px'
                    }}
                  >
                    Price:{' '}
                    {shop.currencyFormats.moneyWithCurrencyFormat.replace(
                      '{{amount}}',
                      p.price
                    )}
                  </div>
                </div>
              </div>
            </Col>
          )
        })}
      </Row>
    </Modal>
  )
}

const enhancers = pipe(
  memo,
  connect(
    (state, props) => ({
      productTypes: state.category.productTypes,
      shop: state.category.shop,
      ...props
    }),
    { ...cartActions }
  )
)

export default enhancers(Products)
