import { all, put, takeLeading, select } from 'redux-saga/effects'
import actions from './action'
import Request from '../../Utilities/API/request'
import generalAction from '../General/action'
import { ProcessType, MessageType } from '../../Utilities/Constant/general'

function* fetchCart() {
  try {
    const result = yield Request.post('checkout/fetch-cart').useAuth().send()
    if (result.status === 200) {
      yield put(actions.fetchCartSuccess(result.data.data))
    }
  } catch (error) {
    console.log(error)
  }
}

function* addCart({ payload }) {
  try {
    yield put(
      generalAction.showMessage({
        content: 'Adding product to cart, please wait...',
        type: MessageType.loading
      })
    )
    const cartId = yield select(state => state.cart.id)
    let result
    if (cartId) {
      result = yield Request.post('checkout/add-cart-lines')
        .body({ cartId, lines: [{ ...payload }] })
        .useAuth()
        .send()
    } else {
      result = yield Request.post('checkout/create-cart')
        .body({ lines: [{ ...payload }] })
        .useAuth()
        .send()
    }
    if (result.status === 200) {
      yield put(actions.addCartSuccess(result.data.data))
      yield put(
        generalAction.showMessage({
          content: 'Product added!',
          type: MessageType.success
        })
      )
    }
  } catch (error) {
    console.log(error)
  }
}

function* updateCart({ payload }) {
  try {
    yield put(
      generalAction.showMessage({
        content: 'Updating product quantity, please wait...',
        type: MessageType.loading
      })
    )
    const cartId = yield select(state => state.cart.id)
    let result = yield Request.post('checkout/update-cart-lines')
      .body({ cartId, lines: [{ ...payload }] })
      .useAuth()
      .send()
    if (result.status === 200) {
      yield put(actions.updateCartSuccess(result.data.data))
      yield put(
        generalAction.showMessage({
          content: 'Product quantity updated!',
          type: MessageType.success
        })
      )
    }
  } catch (error) {
    console.log(error)
  }
}

function* removeCart({ payload }) {
  try {
    yield put(
      generalAction.showMessage({
        content: 'Removing product, please wait...',
        type: MessageType.loading
      })
    )
    const cartId = yield select(state => state.cart.id)
    let result = yield Request.post('checkout/remove-cart-lines')
      .body({ cartId, lines: [{ ...payload }] })
      .useAuth()
      .send()
    if (result.status === 200) {
      yield put(actions.removeCartSuccess(result.data.data))
      yield put(
        generalAction.showMessage({
          content: 'Product removed!',
          type: MessageType.success
        })
      )
    }
  } catch (error) {
    console.log(error)
  }
}

export default function* root() {
  yield all([
    takeLeading(actions.fetchCart.toString(), fetchCart),
    takeLeading(actions.addCart.toString(), addCart),
    takeLeading(actions.updateCart.toString(), updateCart),
    takeLeading(actions.removeCart.toString(), removeCart)
  ])
}
