import React from 'react'
import { Provider } from 'react-redux'
import ErrorBoundary from '../Components/ErrorHandler/ErrorBoundary'
import RouteProvider from './RouteProvider'
import PopupMessage from './PopupMessage'
import Env from './Env'
import { store, history } from './store'
import { HistoryRouter as Router } from 'redux-first-history/rr6'

const App = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Router history={history}>
          <Env>
            <PopupMessage />
            <RouteProvider />
          </Env>
        </Router>
      </Provider>
    </ErrorBoundary>
  )
}

export default App
