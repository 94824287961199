import { createActions } from 'redux-actions'

export default createActions(
  'GET_META',
  'GET_META_SUCCESS',
  'GET_META_FAIL',
  'SELECT',
  {
    prefix: 'CATEGORY'
  }
)
