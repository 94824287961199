import React, { useEffect, useState, memo, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import { pipe } from 'ramda'
import categoryActions from '../../Modules/Category/action'
import photoActions from '../../Modules/Photo/action'
import cartActions from '../../Modules/Cart/action'
import useActions from '../../Utilities/Hooks/useActions'
import { Col, Row, Button, Select } from 'antd'
import { CloseCircleFilled } from '@ant-design/icons'
import { Loading, ImageItem } from '../Shared'
import useNavigation from '../../Utilities/Hooks/useNavigation'
import { ProcessType } from '../../Utilities/Constant/general'

const Cart = ({
  list,
  themes,
  productTypes,
  themeResizer,
  shop,
  checkout,
  cost,
  lines,
  fetchCart,
  updateCart,
  removeCart,
  process
}) => {
  const { link } = useNavigation()
  const getMeta = useActions(categoryActions.getMeta)
  const getProgress = useActions(photoActions.getProgress)

  useEffect(() => {
    getMeta()
    getProgress()
    fetchCart()
  }, [])

  console.log(lines)
  return !list || !themes || !productTypes || !lines ? (
    <Loading />
  ) : (
    <div
      style={{
        maxWidth: '1300px',
        margin: '50px auto 20px auto'
      }}
    >
      {process === ProcessType.Processing && <Loading />}
      {lines.length === 0 ? (
        <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
          <Col
            span={24}
            style={{
              height: '50vh',
              fontSize: 'x-large',
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            You cart is empty.
          </Col>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              size={'large'}
              style={{
                textAlign: 'center',
                backgroundColor: '#fb4343',
                width: '90%',
                maxWidth: '200px',
                color: 'white',
                fontWeight: 'bold',
                borderRadius: '0',
                boxShadow:
                  '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                padding: '7px 7px'
              }}
              onClick={() => {
                link('/photo/gallery')
              }}
            >
              Continue Shopping
            </Button>
          </Col>
        </Row>
      ) : (
        <>
          <Row style={{ margin: '12px 10px 20px 10px' }}>
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div style={{ fontWeight: 'bold', fontSize: 'x-large' }}>
                Your Cart
              </div>
              <Button
                type="primary"
                htmlType="submit"
                size={'large'}
                style={{
                  textAlign: 'center',
                  backgroundColor: '#fb4343',
                  width: '90%',
                  maxWidth: '180px',
                  color: 'white',
                  fontWeight: 'bold',
                  borderRadius: '0',
                  boxShadow:
                    '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                  padding: '7px 7px'
                }}
                onClick={() => {
                  link('/photo/gallery')
                }}
              >
                Continue Shopping
              </Button>
            </Col>
          </Row>
          {lines.map(l => {
            const merchandise = l.merchandise
            const image = list.find(i => i.id === l.imageId)
            const theme = themes.find(t => t.key === image.theme)
            const target = productTypes.find(p => p.id === merchandise.id)
            const resize = themeResizer.find(
              t => t.skuId === merchandise.id && t.theme === image.theme
            )
            let props = {}
            if (resize?.value) {
              props = resize.value
            }
            const images = [
              { url: target?.baseImageUri },
              { url: theme.bgImageUri, ...props },
              { url: image.outputImageUri, ...props },
              { url: theme.fgImageUri, ...props },
              { url: target?.coverImageUri, cover: true }
            ]
            const maxQuantity = l.quantity > 99 ? l.quantity : 99
            const qArray = []
            for (let i = 1; i <= maxQuantity; i++) qArray.push(i)
            return (
              <Row
                key={l.id}
                style={{
                  height: '150px',
                  boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.5)',
                  margin: '12px 10px',
                  position: 'relative'
                }}
              >
                <Col
                  md={6}
                  sm={8}
                  xs={8}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    padding: '5px',
                    overflow: 'hidden'
                  }}
                >
                  <ImageItem images={images} />
                </Col>
                <Col md={18} sm={16} xs={16}>
                  <Row style={{ width: '100%', height: '100%' }}>
                    <Col
                      md={10}
                      sm={20}
                      xs={20}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        fontWeight: 'bold',
                        fontSize: '1.1em'
                      }}
                    >
                      {target.displayName}
                    </Col>
                    <Col
                      md={7}
                      sm={12}
                      xs={24}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left'
                      }}
                    >
                      Quantity :
                      <Select
                        style={{ width: '70px', marginLeft: '10px' }}
                        value={l.quantity}
                        onChange={value => {
                          updateCart({ id: l.id, quantity: value })
                        }}
                      >
                        {qArray.map(q => {
                          return <Select.Option value={q}>{q}</Select.Option>
                        })}
                      </Select>
                    </Col>
                    <Col
                      md={7}
                      sm={12}
                      xs={24}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left'
                      }}
                    >
                      {shop.currencyFormats.moneyWithCurrencyFormat.replace(
                        '{{amount}}',
                        l.cost.totalAmount.amount
                      )}
                    </Col>
                  </Row>
                </Col>
                <CloseCircleFilled
                  style={{
                    fontSize: '1.5em',
                    color: '#abaaaa',
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    removeCart({ id: l.id })
                  }}
                />
              </Row>
            )
          })}
          <Row
            style={{
              margin: '12px 10px',
              position: 'relative',
              justifyContent: 'right'
            }}
          >
            <Col
              span={24}
              style={{
                height: '1px',
                border: '1px solid #d7cfcf',
                margin: '10px 0'
              }}
            ></Col>
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'right',
                fontSize: 'medium',
                marginBottom: '5px'
              }}
            >
              Subtotal :
              {shop.currencyFormats.moneyWithCurrencyFormat.replace(
                '{{amount}}',
                cost.subtotalAmount.amount
              )}
            </Col>
            {cost.totalTaxAmount && (
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  fontSize: 'medium',
                  marginBottom: '5px'
                }}
              >
                Tax :
                {shop.currencyFormats.moneyWithCurrencyFormat.replace(
                  '{{amount}}',
                  cost.totalTaxAmount.amount
                )}
              </Col>
            )}
            {cost.totalDutyAmount && (
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  fontSize: 'medium',
                  marginBottom: '5px'
                }}
              >
                Duty :
                {shop.currencyFormats.moneyWithCurrencyFormat.replace(
                  '{{amount}}',
                  cost.totalDutyAmount.amount
                )}
              </Col>
            )}
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'right',
                fontSize: 'medium',
                fontWeight: 'bold',
                marginBottom: '15px'
              }}
            >
              Total :
              {shop.currencyFormats.moneyWithCurrencyFormat.replace(
                '{{amount}}',
                cost.totalAmount.amount
              )}
            </Col>
            <Col
              xl={4}
              lg={6}
              md={8}
              sm={24}
              xs={24}
              style={{
                display: 'flex',
                justifyContent: 'right',
                marginBottom: '5px'
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                size={'large'}
                style={{
                  textAlign: 'center',
                  backgroundColor: '#fb4343',
                  width: '100%',
                  color: 'white',
                  fontWeight: 'bold',
                  borderRadius: '0',
                  boxShadow:
                    '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                  padding: '7px 7px'
                }}
                onClick={() => {
                  window.location.href = checkout
                }}
              >
                Checkout
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}

const enhancers = pipe(
  memo,
  connect(
    (state, props) => ({
      list: state.photo.list,
      themes: state.category.themes,
      productTypes: state.category.productTypes,
      themeResizer: state.category.themeResizer,
      shop: state.category.shop,
      checkout: state.cart.checkoutUrl,
      cost: state.cart.cost,
      lines: state.cart.lines,
      process: state.cart.process
    }),
    { ...cartActions }
  )
)

export default enhancers(Cart)
