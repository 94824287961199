import { all, put, takeLeading } from 'redux-saga/effects'
import actions from './action'
import cartActions from '../Cart/action'
import Request from '../../Utilities/API/request'

function* init() {
  try {
    const result = yield Request.post('auth/init-device').useAuth().send()
    if (result.status === 200) {
      yield put(actions.refreshTokenSuccess(result.data.data.deviceId))
      yield put(cartActions.fetchCart())
      yield put(actions.initializeDone())
    }
  } catch (error) {
    console.log(error)
  }
}

export default function* root() {
  yield all([takeLeading(actions.initialize.toString(), init)])
}
