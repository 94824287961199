import { handleActions, combineActions } from 'redux-actions'
import actions from './action'
import { ProcessType } from '../../Utilities/Constant/general'

const initialState = {
  product: null,
  process: ProcessType.Init
}

export default handleActions(
  {
    [actions.select]: (state, { payload }) => {
      return { ...state, product: payload }
    },
    [combineActions(
      actions.fetchCart,
      actions.addCart,
      actions.updateCart,
      actions.removeCart
    )]: state => {
      return { ...state, process: ProcessType.Processing }
    },
    [combineActions(
      actions.fetchCartSuccess,
      actions.addCartSuccess,
      actions.updateCartSuccess,
      actions.removeCartSuccess
    )]: (state, { payload }) => {
      return { ...state, ...payload, process: ProcessType.Success }
    }
  },
  initialState
)
