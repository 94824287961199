import { createActions } from 'redux-actions'

export default createActions(
  'SELECT',
  'FETCH_CART',
  'FETCH_CART_SUCCESS',
  'FETCH_CART_FAIL',
  'ADD_CART',
  'ADD_CART_SUCCESS',
  'ADD_CART_FAIL',
  'UPDATE_CART',
  'UPDATE_CART_SUCCESS',
  'UPDATE_CART_FAIL',
  'REMOVE_CART',
  'REMOVE_CART_SUCCESS',
  'REMOVE_CART_FAIL',
  {
    prefix: 'CART'
  }
)
