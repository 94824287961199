import React from 'react'
import ReactDOM from 'react-dom/client'
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { getBaseData } from './Utilities/API/userStorage'
import { store } from './App/store'
import generalActions from './Modules/General/action'

const refreshAuthLogic = failedRequest => {
  return axios
    .post(
      `/api/v1/auth/init-device`,
      {},
      { header: { 'X-DEVICE-ID': getBaseData() }, skipAuthRefresh: true }
    )
    .then(tokenRefreshResponse => {
      if (tokenRefreshResponse.data.data.deviceId) {
        store.dispatch(
          generalActions.refreshTokenSuccess(
            tokenRefreshResponse.data.data.deviceId
          )
        )
        failedRequest.response.config.headers['X-DEVICE-ID'] = getBaseData()
        return Promise.resolve()
      } else {
        //store.dispatch(userActions.logoutSuccess())
        throw failedRequest
      }
    })
}

createAuthRefreshInterceptor(axios, refreshAuthLogic)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
