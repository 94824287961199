const key = window.btoa('device-token')

export function storeBaseData(payload) {
  localStorage.setItem(key, payload)
}

export function clearBaseData() {
  localStorage.removeItem(key)
}

export function getBaseData() {
  try {
    const payload = localStorage.getItem(key)
    if (!payload) return null
    return payload
  } catch (error) {
    clearBaseData()
    return null
  }
}
