import { handleActions } from 'redux-actions'
import actions from './action'
import { storeBaseData } from '../../Utilities/API/userStorage'

const initialState = {
  initialized: false,
  message: null
}

export default handleActions(
  {
    [actions.initializeDone]: state => {
      return {
        ...state,
        initialized: true
      }
    },
    [actions.refreshTokenSuccess]: (state, { payload }) => {
      storeBaseData(payload)
      return state
    },
    [actions.showMessage]: (state, { payload }) => {
      return {
        ...state,
        message: payload
      }
    }
  },
  initialState
)
