import React, { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Col, Row } from 'antd'
import {
  CameraOutlined,
  AppstoreOutlined,
  RocketOutlined,
  InsertRowLeftOutlined
} from '@ant-design/icons'
import useNavigation from '../../Utilities/Hooks/useNavigation'

const PhotoMenu = ({ radius }) => {
  const { link } = useNavigation()
  const locations = useLocation()
  return (
    <Row justify={'center'}>
      <Col md={24} sm={0} xs={0} style={{ height: '20px' }}></Col>
      <Col
        md={23}
        sm={24}
        xs={24}
        style={{
          background: 'linear-gradient(to top, #ff930b, #ffd095)',
          borderRadius: radius ? '10px' : '0'
        }}
      >
        <Row justify={'center'}>
          <Col
            md={24}
            sm={0}
            xs={0}
            style={{
              textAlign: 'center'
            }}
          >
            <div
              style={{
                fontSize: 'xx-large',
                color: 'white',
                fontWeight: 'bold',
                fontStyle: 'italic',
                margin: '13px'
              }}
            >
              Create Your Own AI Photo
            </div>
          </Col>
          <Col
            xl={10}
            lg={14}
            md={18}
            sm={20}
            xs={24}
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              marginTop: '10px',
              marginBottom: '10px'
            }}
          >
            <div
              style={{ textAlign: 'center', cursor: 'pointer' }}
              onClick={() => link('/photo/upload')}
            >
              <Row>
                <Col span={24}>
                  <div>
                    <CameraOutlined
                      className="header-icon"
                      style={{
                        color: '#f15e5e',
                        border: `2px solid ${locations.pathname.includes('upload') ? '#f15e5e' : 'white'}`
                      }}
                    />
                  </div>
                </Col>
                <Col md={24} sm={0} xs={0}>
                  <div className="header-icon-text">Upload Photo</div>
                </Col>
                <Col md={0} sm={24} xs={24}>
                  <div className="header-icon-text">Photo</div>
                </Col>
              </Row>
            </div>
            <div
              style={{ textAlign: 'center', cursor: 'pointer' }}
              onClick={() => link('/photo/theme')}
            >
              <Row>
                <Col span={24}>
                  <div>
                    <AppstoreOutlined
                      className="header-icon"
                      style={{
                        color: '#85c36c',
                        border: `2px solid ${locations.pathname.includes('theme') ? '#85c36c' : 'white'}`
                      }}
                    />
                  </div>
                </Col>
                <Col md={24} sm={0} xs={0}>
                  <div className="header-icon-text">Choose Theme</div>
                </Col>
                <Col md={0} sm={24} xs={24}>
                  <div className="header-icon-text">Theme</div>
                </Col>
              </Row>
            </div>
            <div
              style={{ textAlign: 'center', cursor: 'pointer' }}
              onClick={() => link('/photo/generate')}
            >
              <Row>
                <Col span={24}>
                  <div>
                    <RocketOutlined
                      className="header-icon"
                      style={{
                        color: '#6c7bc3',
                        border: `2px solid ${locations.pathname.includes('generate') ? '#6c7bc3' : 'white'}`
                      }}
                    />
                  </div>
                </Col>
                <Col md={24} sm={0} xs={0}>
                  <div className="header-icon-text">Generate Photo</div>
                </Col>
                <Col md={0} sm={24} xs={24}>
                  <div className="header-icon-text">Generate</div>
                </Col>
              </Row>
            </div>
            <div
              style={{ textAlign: 'center', cursor: 'pointer' }}
              onClick={() => link('/photo/gallery')}
            >
              <Row>
                <Col span={24}>
                  <div>
                    <InsertRowLeftOutlined
                      className="header-icon"
                      style={{
                        color: '#a19797',
                        border: `2px solid ${locations.pathname.includes('gallery') ? '#a19797' : 'white'}`
                      }}
                    />
                  </div>
                </Col>
                <Col md={24} sm={0} xs={0}>
                  <div className="header-icon-text">My Gallery</div>
                </Col>
                <Col md={0} sm={24} xs={24}>
                  <div className="header-icon-text">Gallery</div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default PhotoMenu
