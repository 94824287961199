import React from 'react'
import { useSelector } from 'react-redux'
import { Col, Row, Badge } from 'antd'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ShoppingCartOutlined
} from '@ant-design/icons'
import useNavigation from '../Utilities/Hooks/useNavigation'
import banner from '../Images/logo.png'

const Header = ({ menu, click }) => {
  const { link } = useNavigation()
  const count = useSelector(state => state.cart.totalQuantity)
  return (
    <Row
      style={{
        width: '100%',
        height: '70px',
        padding: '10px 20px',
        position: 'sticky',
        top: '0',
        boxShadow: '0 2px 4px -1px rgba(57,76,96,.15)',
        zIndex: '20',
        backgroundColor: 'white'
      }}
    >
      <Col md={24} sm={0} xs={0} style={{ height: '100%' }}>
        <Row style={{ height: '100%' }} justify={'space-between'}>
          <div style={{ display: 'flex', height: '100%' }}>
            {menu ? (
              <MenuFoldOutlined
                style={{ fontSize: '24px', cursor: 'pointer' }}
                onClick={() => click(!menu)}
              />
            ) : (
              <MenuUnfoldOutlined
                style={{ fontSize: '24px', cursor: 'pointer' }}
                onClick={() => click(!menu)}
              />
            )}
            <img
              src={banner}
              style={{
                maxHeight: '100%',
                borderRadius: '5px',
                marginLeft: '20px',
                cursor: 'pointer'
              }}
              onClick={() => {
                window.location.href =
                  'https://shop.fridayfitter.com/pages/opening'
              }}
            />
          </div>
          <div
            style={{
              marginRight: '20px',
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={() => {
              link('/cart')
            }}
          >
            <Badge count={count ?? 0}>
              <ShoppingCartOutlined
                style={{
                  fontSize: '2.5em',
                  cursor: 'pointer'
                }}
              />
            </Badge>
          </div>
        </Row>
      </Col>
      <Col md={0} sm={24} xs={24} style={{ height: '100%' }}>
        <Row style={{ height: '100%' }} justify={'space-between'}>
          <img
            src={banner}
            style={{
              maxHeight: '100%',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
            onClick={() => {
              window.location.href =
                'https://shop.fridayfitter.com/pages/opening'
            }}
          />
          <div style={{ display: 'flex' }}>
            <div
              style={{
                marginRight: '20px',
                display: 'flex',
                alignItems: 'center'
              }}
              onClick={() => {
                link('/cart')
              }}
            >
              <Badge count={count ?? 0}>
                <ShoppingCartOutlined
                  style={{
                    fontSize: '2.5em',
                    cursor: 'pointer'
                  }}
                />
              </Badge>
            </div>
            {menu ? (
              <MenuUnfoldOutlined
                style={{ fontSize: '24px', cursor: 'pointer' }}
                onClick={() => click(!menu)}
              />
            ) : (
              <MenuFoldOutlined
                style={{ fontSize: '24px', cursor: 'pointer' }}
                onClick={() => click(!menu)}
              />
            )}
          </div>
        </Row>
      </Col>
    </Row>
  )
}

export default Header
