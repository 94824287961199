import React, { useState } from 'react'
import { Navigate, Outlet, useParams, useMatch } from 'react-router-dom'
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar'
import {
  HomeOutlined,
  CameraOutlined,
  ShoppingOutlined,
  ShoppingCartOutlined
} from '@ant-design/icons'
import useNavigation from '../Utilities/Hooks/useNavigation'
import Header from './Header'

const Layout = () => {
  const { link } = useNavigation()
  const { lang } = useParams()
  const isHome = useMatch('/:lang/')
  const [openMenu, setOpenMenu] = useState(false)
  if (['en', 'cn'].includes(lang)) {
    return (
      <>
        <Header menu={openMenu} click={setOpenMenu} />
        <div
          style={{
            height: 'calc(100vh - var(--browser-address-bar, 0px) - 72px)',
            display: 'flex',
            marginTop: '2px'
          }}
        >
          <Sidebar
            backgroundColor={'white'}
            rootStyles={{
              height: '100%',
              borderRightWidth: '1px!important',
              borderRightStyle: 'solid!important'
            }}
            collapsed={!openMenu}
            toggled={openMenu}
            collapsedWidth={0}
            customBreakPoint={'768px'}
            onBackdropClick={() => setOpenMenu(!openMenu)}
            rtl
            closeOnClick
          >
            <Menu rootStyles={{ direction: 'ltr' }}>
              <div
                style={{
                  fontWeight: 'bold',
                  padding: '20px',
                  margin: '5px',
                  direction: 'ltr',
                  borderBottom: '1px solid #efefef'
                }}
              >
                Menu
              </div>
              <MenuItem>
                <div
                  onClick={() => {
                    window.location.href =
                      'https://shop.fridayfitter.com/pages/opening'
                  }}
                >
                  <HomeOutlined />
                  <span style={{ marginLeft: '10px' }}>Home</span>
                </div>
              </MenuItem>
              <MenuItem>
                <div
                  onClick={() => {
                    link('/photo')
                    setOpenMenu(false)
                  }}
                >
                  <CameraOutlined />
                  <span style={{ marginLeft: '10px' }}>Generate Photo</span>
                </div>
              </MenuItem>

              <MenuItem>
                <div
                  onClick={() => {
                    link('/cart')
                    setOpenMenu(false)
                  }}
                >
                  <ShoppingCartOutlined />
                  <span style={{ marginLeft: '10px' }}>Shopping Cart</span>
                </div>
              </MenuItem>
              <MenuItem>
                <ShoppingOutlined />
                <span style={{ marginLeft: '10px' }}>Order</span>
              </MenuItem>
            </Menu>
          </Sidebar>
          <div style={{ height: '100%', width: '100%', overflow: 'auto' }}>
            <Outlet />
          </div>
        </div>
      </>
    )
  } else {
    return <Navigate to="/en/" />
  }
}

export default Layout
