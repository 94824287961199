import general from '../Modules/General/reducer'
import category from '../Modules/Category/reducer'
import photo from '../Modules/Photo/reducer'
import cart from '../Modules/Cart/reducer'

export default routerReducer => {
  return {
    router: routerReducer,
    general,
    category,
    photo,
    cart
  }
}
