import { createActions } from 'redux-actions'

export default createActions(
  'SELECT',
  'ADD',
  'UPLOAD',
  'UPLOAD_SUCCESS',
  'UPLOAD_FAIL',
  'GENERATE',
  'GENERATE_SUCCESS',
  'GENERATE_FAIL',
  'CLEAR_ESTIMATE',
  'GET_PROGRESS',
  'GET_PROGRESS_SUCCESS',
  'GET_PROGRESS_FAIL',
  'SET_PRODUCT',
  'CLEAR_PRODUCT',
  'PLACE_ORDER',
  'PLACE_ORDER_SUCCESS',
  'PLACE_ORDER_FAIL',
  'CLEAR_ORDER',
  'PROCESS_CHANGE',
  {
    prefix: 'PHOTO'
  }
)
