import React, { useEffect, useState, memo, useCallback } from 'react'
import { connect } from 'react-redux'
import { pipe } from 'ramda'
import { Col, Row, Modal, Button } from 'antd'
import categoryActions from '../../Modules/Category/action'
import { CheckCircleFilled } from '@ant-design/icons'
import { Loading, ImageItem } from '../Shared'
import PhotoMenu from './menu'
import useNavigation from '../../Utilities/Hooks/useNavigation'

const Theme = ({ uploadPhoto, themes, theme, getMeta, select }) => {
  const { link } = useNavigation()

  useEffect(() => {
    getMeta()
  }, [])

  const [openImg, setOpenImage] = useState()

  const clickImage = useCallback(
    id => {
      const target = themes.find(t => t.key === id)
      setOpenImage(target)
    },
    [themes]
  )

  return !themes ? (
    <Loading />
  ) : (
    <>
      <Row>
        <Col md={24} sm={0} xs={0} style={{ marginBottom: '20px' }}>
          <PhotoMenu radius />
        </Col>
      </Row>
      <Row
        style={{
          width: '98%',
          maxWidth: '1300px',
          maxHeight: '95%',
          height: 'fit-content',
          margin: 'auto'
        }}
      >
        {themes.map(t => {
          return (
            <Col
              xl={3}
              lg={4}
              mg={6}
              sm={6}
              xs={8}
              key={t.key}
              style={{ height: 'fit-content' }}
            >
              <div
                style={{
                  width: '100%',
                  paddingTop: '212%',
                  position: 'relative'
                }}
              >
                <ThemeItem
                  link={link}
                  photo={Boolean(uploadPhoto)}
                  select={select}
                  selected={theme === t.key}
                  id={t.key}
                  images={[
                    { url: t.bgImageUri },
                    { url: t.subjectImageUri },
                    { url: t.fgImageUri }
                  ]}
                  clickImg={clickImage}
                />
              </div>
            </Col>
          )
        })}
        <Col md={0} sm={24} xs={24} style={{ height: '113px' }}></Col>
      </Row>
      <Row
        style={{ width: '100%', position: 'fixed', bottom: '0', zIndex: '10' }}
      >
        <Col md={0} sm={24} xs={24}>
          <PhotoMenu />
        </Col>
      </Row>
      <Modal
        open={Boolean(openImg)}
        onCancel={() => setOpenImage(null)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        centered
      >
        <div
          style={{
            height: 'calc(80vh - var(--browser-address-bar, 0px))',
            textAlign: 'center'
          }}
        >
          {Boolean(openImg) && (
            <ImageItem
              images={[
                { url: openImg?.bgImageUri },
                { url: openImg?.subjectImageUri },
                { url: openImg?.fgImageUri }
              ]}
            />
          )}
        </div>
        <div style={{ marginTop: '10px', textAlign: 'center' }}>
          {Boolean(openImg) && (
            <Button
              type="primary"
              htmlType="submit"
              size={'large'}
              style={{
                backgroundColor:
                  openImg.key === theme
                    ? Boolean(uploadPhoto)
                      ? '#6c7bc3'
                      : '#f15e5e'
                    : '#85c36c',
                color: 'white',
                fontWeight: 'bold',
                borderRadius: '0',
                zIndex: '5',
                boxShadow:
                  '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                margin: 'auto',
                padding: '7px 12px',
                height: 'fit-content'
              }}
              onClick={() => {
                if (openImg.key === theme) {
                  if (Boolean(uploadPhoto)) link('/photo/generate')
                  else link('/photo/upload')
                } else {
                  select(openImg.key)
                }
              }}
            >
              {openImg.key === theme
                ? Boolean(uploadPhoto)
                  ? 'Generate AI Photo'
                  : 'Upload Photo'
                : 'Select Theme'}
            </Button>
          )}
        </div>
      </Modal>
    </>
  )
}

const ThemeItem = React.memo(
  ({ selected, link, id, photo, images, select, clickImg }) => {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
          position: 'absolute',
          top: '0',
          left: '0'
        }}
      >
        <div
          style={{
            margin: 'auto',
            width: 'calc(100% - 2px)',
            height: 'calc(100% - 2px)',
            border: '1px solid black'
          }}
        >
          <CheckCircleFilled
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              fontSize: 'x-large',
              color: selected ? 'green' : 'grey',
              zIndex: '5',
              backgroundColor: 'white',
              borderRadius: '50%',
              cursor: 'pointer'
            }}
            onClick={() => {
              if (selected) select(null)
              else select(id)
            }}
          />
          <ImageItem id={id} images={images} click={clickImg} />
          <Button
            type="primary"
            htmlType="submit"
            size={'large'}
            style={{
              backgroundColor: selected
                ? photo
                  ? '#6c7bc3'
                  : '#f15e5e'
                : '#bf710e',
              color: 'white',
              fontWeight: 'bold',
              borderRadius: '0',
              position: 'absolute',
              bottom: '8px',
              zIndex: '5',
              boxShadow:
                '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
              left: '50%',
              transform: 'translateX(-50%)',
              padding: '3px 12px',
              height: 'fit-content'
            }}
            onClick={() => {
              if (selected) {
                if (photo) link('/photo/generate')
                else link('/photo/upload')
              } else {
                clickImg(id)
              }
            }}
          >
            {selected ? (photo ? 'Generate' : 'Photo') : 'View'}
          </Button>
        </div>
      </div>
    )
  },
  (prev, next) => {
    return (
      prev.photo === next.photo &&
      prev.selected === next.selected &&
      prev.images === next.images &&
      prev.id === next.id &&
      prev.clickImg === next.clickImg
    )
  }
)

const enhancers = pipe(
  memo,
  connect(
    (state, props) => ({
      uploadPhoto: state.photo.upload,
      themes: state.category.themes,
      theme: state.category.theme
    }),
    { ...categoryActions }
  )
)

export default enhancers(Theme)
