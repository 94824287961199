import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'
import { pipe } from 'ramda'
import photoActions from '../../Modules/Photo/action'
import { Col, Row, Button } from 'antd'
import PhotoMenu from './menu'
import useNavigation from '../../Utilities/Hooks/useNavigation'
import photo from '../../Images/phone_photo.png'
import phoneCase from '../../Images/phone_case.png'
import plus from '../../Images/plus.png'
import arrow from '../../Images/arrow.png'
import ai from '../../Images/ai.png'
import sample from '../../Images/sample.png'
import { Loading, ImageItem } from '../Shared'
import { ProcessType } from '../../Utilities/Constant/general'

const Generate = ({
  uploadPhoto,
  process,
  themes,
  theme,
  upload,
  processChange
}) => {
  const { link } = useNavigation()
  const targetTheme = themes && theme ? themes.find(t => t.key === theme) : null

  useEffect(() => {
    return () => {
      processChange(ProcessType.Init)
    }
  }, [])

  useEffect(() => {
    if (process === ProcessType.Success) link('/photo/gallery')
  }, [process])

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        height: '100%'
      }}
    >
      {process === ProcessType.Processing && <Loading />}
      <div style={{ flex: '0 1 auto' }}>
        <Row>
          <Col md={24} sm={0} xs={0}>
            <PhotoMenu radius />
          </Col>
        </Row>
      </div>
      <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
        <Row style={{ height: '100%' }} justify={'center'}>
          <Col
            md={20}
            sm={24}
            xs={24}
            style={{ height: '100%', backgroundColor: '#fcd895' }}
          >
            <Row style={{ height: '100%' }}>
              <Col
                span={24}
                style={{
                  textAlign: 'center',
                  fontSize: 'x-large',
                  fontWeight: 'bold',
                  height: 'fit-content',
                  marginTop: '10px'
                }}
              >
                How does it works?
              </Col>
              <Col span={10} style={{ height: '20%', textAlign: 'right' }}>
                <div
                  style={{
                    height: '100%',
                    textAlign: 'center',
                    float: 'right'
                  }}
                >
                  {uploadPhoto ? (
                    <div
                      style={{
                        height: '80%',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <div
                        style={{
                          borderRadius: '50%',
                          width: '100px',
                          height: '100px',
                          position: 'relative',
                          backgroundImage: `url(${uploadPhoto.preView})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          backgroundSize: 'cover'
                        }}
                      />
                    </div>
                  ) : (
                    <img
                      src={photo}
                      style={{ maxHeight: '80%', maxWidth: '100%' }}
                    />
                  )}
                  <div style={{ fontSize: 'large', fontWeight: 'bold' }}>
                    Upload Photo
                  </div>
                </div>
              </Col>
              <Col
                span={4}
                style={{
                  height: '20%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  src={plus}
                  style={{ maxHeight: '40%', maxWidth: '100%' }}
                />
              </Col>
              <Col span={10} style={{ height: '20%', textAlign: 'left' }}>
                <div
                  style={{ height: '100%', textAlign: 'center', float: 'left' }}
                >
                  {targetTheme ? (
                    <div
                      style={{
                        height: '80%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        overflow: 'hidden'
                      }}
                    >
                      <div style={{ height: '200%', width: '100%' }}>
                        <ImageItem
                          images={[
                            { url: targetTheme.bgImageUri },
                            { url: targetTheme.subjectImageUri },
                            { url: targetTheme.fgImageUri }
                          ]}
                        />
                      </div>
                    </div>
                  ) : (
                    <img
                      src={phoneCase}
                      style={{ maxHeight: '80%', maxWidth: '100%' }}
                    />
                  )}
                  <div style={{ fontSize: 'large', fontWeight: 'bold' }}>
                    Pick a Theme
                  </div>
                </div>
              </Col>
              <Col span={24} style={{ height: '7%', textAlign: 'center' }}>
                <img
                  src={arrow}
                  style={{ maxHeight: '100%', transform: 'rotate(90deg)' }}
                />
              </Col>
              <Col span={24} style={{ height: '20%', textAlign: 'center' }}>
                <div style={{ height: '100%', textAlign: 'center' }}>
                  <img
                    src={ai}
                    style={{ maxHeight: '80%', maxWidth: '100%' }}
                  />
                  <div style={{ fontSize: 'large', fontWeight: 'bold' }}>
                    AI Magic at Work
                  </div>
                </div>
              </Col>
              <Col span={24} style={{ height: '7%', textAlign: 'center' }}>
                <img
                  src={arrow}
                  style={{
                    maxHeight: '100%',
                    transform: 'rotate(-90deg) scaleX(-1)'
                  }}
                />
              </Col>
              <Col
                span={12}
                style={{
                  height: '24%',
                  textAlign: 'right',
                  float: 'right',
                  paddingRight: '40px'
                }}
              >
                <img
                  src={sample}
                  style={{
                    maxHeight: '120%',
                    maxWidth: '100%',
                    transform: 'rotate(18deg)'
                  }}
                />
              </Col>
              <Col
                span={12}
                style={{
                  height: '20%',
                  float: 'left',
                  paddingTop: '15px',
                  paddingLeft: '30px'
                }}
              >
                <div style={{ fontSize: 'large', fontWeight: 'bold' }}>
                  A personalized phone case
                </div>
                <div style={{ fontSize: 'large', fontWeight: 'bold' }}>
                  ready for purchase
                </div>
                <Button
                  type="primary"
                  htmlType="submit"
                  size={'large'}
                  style={{
                    backgroundColor: uploadPhoto
                      ? theme
                        ? '#6c7bc3'
                        : '#5fb344'
                      : '#f15e5e',
                    color: 'white',
                    fontWeight: 'bold',
                    borderRadius: '0',
                    boxShadow:
                      '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                    padding: '7px 12px',
                    height: 'fit-content',
                    marginTop: '10px'
                  }}
                  onClick={() => {
                    if (uploadPhoto) {
                      if (theme)
                        upload({
                          photo: uploadPhoto,
                          theme: theme
                        })
                      else link('/photo/theme')
                    } else {
                      link('/photo/upload')
                    }
                  }}
                >
                  {uploadPhoto
                    ? theme
                      ? 'Generate AI Photo'
                      : 'Select Theme'
                    : 'Upload Photo'}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div style={{ flex: '0 1 auto' }}>
        <Row>
          <Col md={0} sm={24} xs={24}>
            <PhotoMenu />
          </Col>
        </Row>
      </div>
    </div>
  )
}

const enhancers = pipe(
  memo,
  connect(
    (state, props) => ({
      uploadPhoto: state.photo.upload,
      process: state.photo.process,
      themes: state.category.themes,
      theme: state.category.theme
    }),
    { ...photoActions }
  )
)

export default enhancers(Generate)
