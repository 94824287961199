import React, { memo } from 'react'
import { connect } from 'react-redux'
import { pipe } from 'ramda'
import banner from '../../Images/banner.jpg'
import { Col, Row, Button } from 'antd'
import useNavigation from '../../Utilities/Hooks/useNavigation'

const Home = () => {
  const { link } = useNavigation()
  return (
    <Row
      className="background"
      align={'middle'}
      justify={'center'}
      style={{
        height: 'calc(100vh - var(--browser-address-bar, 0px))'
      }}
    >
      <Col span={24} style={{ height: '50%' }}>
        <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
          <img
            src={banner}
            style={{ maxWidth: '1000px', width: '80%', maxHeight: '100%' }}
          />
        </Row>
      </Col>
      <Col span={24}>
        <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
          <Button
            type="primary"
            size={'large'}
            shape="round"
            style={{
              backgroundColor: '#ffac44',
              color: 'black',
              fontWeight: 'bold'
            }}
            onClick={() => {
              link(`/photo`)
            }}
          >
            開始製作專屬機殼
          </Button>
        </Row>
      </Col>
    </Row>
  )
}

const enhancers = pipe(memo)

export default enhancers(Home)
