export const MessageType = {
  loading: 'loading',
  error: 'error',
  success: 'success'
}

export const ProcessType = {
  Init: 'init',
  Processing: 'processing',
  Success: 'Success',
  Failed: 'failed'
}
