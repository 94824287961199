import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { createReduxHistoryContext } from 'redux-first-history'
import { createBrowserHistory } from 'history'
import createRootReducer from './reducer'
import rootSaga from './saga'

const sagaMiddleware = createSagaMiddleware()

const DEVELOPMENT_MODE = process.env.NODE_ENV === 'development'

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() })

/* eslint-disable no-undef */
const middleware = [sagaMiddleware].concat(routerMiddleware)

export const store = configureStore({
  reducer: createRootReducer(routerReducer),
  middleware: getDefaultMiddleware => {
    return middleware
  }
})

let sagaTask = sagaMiddleware.run(function* () {
  yield rootSaga()
})

if (DEVELOPMENT_MODE) {
  /* eslint-disable no-unused-expressions */
  module.hot.accept('./reducer', () => {
    const nextReducer = require('./reducer').default
    store.replaceReducer(nextReducer)
  })

  module.hot.accept('./saga', () => {
    const nextSaga = require('./saga').default
    sagaTask.cancel()
    sagaTask.done.then(() => {
      sagaTask = sagaMiddleware.run(function* replacedSaga() {
        yield nextSaga()
      })
    })
  })
}

export const history = createReduxHistory(store)
