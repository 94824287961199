import { handleActions, combineActions } from 'redux-actions'
import actions from './action'
import { ProcessType } from '../../Utilities/Constant/general'

const initialState = {
  images: [],
  upload: null,
  estimate: null,
  list: null,
  product: null,
  orderNumber: null,
  process: ProcessType.Init
}

export default handleActions(
  {
    [actions.add]: (state, { payload }) => {
      return { ...state, images: [...state.images, payload] }
    },
    [actions.select]: (state, { payload }) => {
      return { ...state, upload: payload }
    },
    [actions.processChange]: (state, { payload }) => {
      return { ...state, process: payload }
    },
    [actions.uploadSuccess]: (state, { payload }) => {
      state.upload.imageId = payload
      return { ...state }
    },
    [combineActions(actions.generate, actions.clearEstimate)]: state => {
      return { ...state, estimate: null }
    },
    [actions.generateSuccess]: (state, { payload }) => {
      return { ...state, upload: null, estimate: payload }
    },
    [actions.getProgressSuccess]: (state, { payload }) => {
      return { ...state, list: payload }
    },
    [actions.setProduct]: (state, { payload }) => {
      return { ...state, product: payload }
    },
    [actions.clearProduct]: state => {
      return { ...state, product: null }
    },
    [actions.placeOrderSuccess]: (state, { payload }) => {
      return { ...state, orderNumber: payload }
    },
    [actions.clearOrder]: state => {
      return { ...state, orderNumber: null }
    }
  },
  initialState
)
