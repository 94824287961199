import React from 'react'
import { Image } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export const Loading = () => {
  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100vw',
          height: '100vh',
          backgroundColor: 'black',
          zIndex: '888',
          opacity: '50%'
        }}
      ></div>
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          zIndex: '999'
        }}
      >
        <LoadingOutlined style={{ fontSize: 48, color: '#ffc36a' }} spin />
      </div>
    </>
  )
}

export const ImageItem = React.memo(
  ({ id, images, click }) => {
    return (
      <div
        style={{ position: 'relative', width: '100%', height: '100%' }}
        onClick={e => {
          if (click) {
            e.stopPropagation()
            click(id)
          }
        }}
      >
        {images
          .filter(i => Boolean(i))
          .map((i, index) => {
            const { width, deltaX, deltaY, url, cover } = i
            let transform = ''
            if (width) {
              transform = `${transform} scale(${width / 2367})`
            }
            if (deltaX) {
              transform = `${transform} translateX(${(deltaX / 2367) * 100}%)`
            }
            if (deltaY) {
              transform = `${transform} translateY(${(deltaY / 2367) * 100}%)`
            }
            return (
              <div className="theme-image" key={index}>
                {url && (
                  <div
                    style={{
                      height: '100%',
                      width: 'fit-content',
                      margin: 'auto',
                      position: 'relative'
                    }}
                  >
                    {cover && (
                      <div
                        style={{
                          width: '50px',
                          height: '100%',
                          backgroundColor: 'white',
                          position: 'absolute',
                          left: '-50px',
                          top: '0'
                        }}
                      />
                    )}
                    <img
                      src={url}
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        width: 'auto',
                        height: 'auto',
                        margin: 'auto',
                        transform: transform
                      }}
                    />
                    {cover && (
                      <div
                        style={{
                          width: '50px',
                          height: '100%',
                          backgroundColor: 'white',
                          position: 'absolute',
                          right: '-50px',
                          top: '0'
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            )
          })}
      </div>
    )
  },
  (prev, next) => {
    return (
      prev.id === next.id &&
      prev.images === next.images &&
      prev.click === next.click
    )
  }
)
