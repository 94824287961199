import { all, call, fork } from 'redux-saga/effects'
import general from '../Modules/General/saga'
import category from '../Modules/Category/saga'
import photo from '../Modules/Photo/saga'
import cart from '../Modules/Cart/saga'

export default function* rootSaga() {
  try {
    yield all([general, category, photo, cart].map(saga => call(saga)))
  } catch (error) {
    yield fork(rootSaga)
  }
}
