import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import generalActions from '../Modules/General/action'
import { Loading } from '../Components/Shared'
import vhCheck from 'vh-check'

const Env = ({ init, initialized, children }) => {
  vhCheck('browser-address-bar')
  useEffect(() => {
    init()
  }, [])

  return initialized ? <>{children}</> : <Loading />
}

export default connect(
  state => ({
    ...state.general
  }),
  {
    init: generalActions.initialize
  }
)(Env)
