import React, { useEffect, useState, memo, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import { pipe } from 'ramda'
import { Col, Row, Button, Carousel, Collapse, Modal } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import PhotoMenu from './menu'
import useNavigation from '../../Utilities/Hooks/useNavigation'
import categoryActions from '../../Modules/Category/action'
import photoActions from '../../Modules/Photo/action'
import cartActions from '../../Modules/Cart/action'
import useActions from '../../Utilities/Hooks/useActions'
import Products from './products'
import { Loading, ImageItem } from '../Shared'
import { ReactComponent as Phone } from '../../Images/mobile-phone.svg'

const Gallery = ({
  list,
  themes,
  productTypes,
  themeResizer,
  shop,
  product,
  getMeta,
  getProgress
}) => {
  const { link } = useNavigation()
  const addCart = useActions(cartActions.addCart)
  const carouselRef = useRef()
  const timer = useRef(-1)
  const [selectImg, setSelectImg] = useState(0)
  const [openProduct, setOpenProduct] = useState(false)
  const [preview, setPreview] = useState(null)
  useEffect(() => {
    getMeta()
    getProgress()
    if (!product) setOpenProduct(true)
    return () => {
      clearInterval(timer.current)
    }
  }, [])

  useEffect(() => {
    if (!list || list.find(l => l.status === 'IN_PROGRESS')) {
      timer.current = setTimeout(() => {
        getProgress()
      }, 10000)
    }
    return () => {
      clearTimeout(timer.current)
    }
  }, [list])

  const addProduct = useCallback(() => {
    const target = productTypes.find(p => p.id === product)
    addCart({
      imageId: list[selectImg].id,
      skuId: product,
      sku: target.sku, //
      quantity: 1
    })
  }, [list, productTypes, selectImg, product])

  return !list || !themes || !productTypes ? (
    <Loading />
  ) : (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        height: '100%'
      }}
    >
      <div style={{ flex: '0 1 auto' }}>
        <Row>
          <Col md={24} sm={0} xs={0}>
            <PhotoMenu radius />
          </Col>
          {list.length !== 0 && (
            <Col
              md={0}
              sm={24}
              xs={24}
              style={{ height: 'fit-content', backgroundColor: '#fcd895' }}
            >
              <Collapse
                items={[
                  {
                    key: '1',
                    label: 'Product Information',
                    children: (
                      <PhotoInfo
                        photo={list[selectImg]?.sourceImageUri}
                        theme={themes.find(
                          t => t.key === list[selectImg]?.theme
                        )}
                        openProduct={setOpenProduct}
                        phoneName={
                          productTypes.find(p => p.id === product)?.displayName
                        }
                        price={productTypes.find(p => p.id === product)?.price}
                        format={shop.currencyFormats.moneyWithCurrencyFormat}
                      />
                    )
                  }
                ]}
                defaultActiveKey={['']}
              />
            </Col>
          )}
        </Row>
      </div>
      <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
        <Row
          style={{
            height: '100%',
            width: '100%'
          }}
        >
          <Col
            span={24}
            style={{
              height: '100%'
            }}
          >
            {list.length === 0 ? (
              <Row
                align={'middle'}
                justify={'center'}
                style={{ height: '100%' }}
              >
                <Col
                  span={24}
                  style={{
                    height: '100%',
                    fontSize: 'large',
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  You don't have any AI Photo.
                </Col>
              </Row>
            ) : (
              <Row
                style={{
                  maxWidth: '1200px',
                  margin: 'auto',
                  height: '100%'
                }}
              >
                <Col
                  md={16}
                  sm={24}
                  xs={24}
                  style={{ textAlign: 'right', height: '100%' }}
                  className={'gallery-carousel'}
                >
                  <LeftOutlined
                    style={{
                      position: 'absolute',
                      left: '5px',
                      top: '50%',
                      zIndex: '5',
                      fontSize: 'x-large',
                      cursor: 'pointer'
                    }}
                    onClick={() => carouselRef.current.prev()}
                  />
                  <Carousel
                    ref={carouselRef}
                    className="theme-dot"
                    afterChange={current => {
                      setSelectImg(current)
                    }}
                  >
                    {list.map(l => {
                      const theme = themes.find(t => t.key === l.theme)
                      const target = productTypes.find(p => p.id === product)
                      const resize = themeResizer.find(
                        t => t.skuId === product && t.theme === l.theme
                      )
                      let props = {}
                      if (resize?.value) {
                        props = resize.value
                      }
                      const images = [
                        { url: target?.baseImageUri },
                        { url: theme.bgImageUri, ...props },
                        { url: l.outputImageUri, ...props },
                        { url: theme.fgImageUri, ...props },
                        { url: target?.coverImageUri, cover: true }
                      ]
                      return (
                        <div key={l.id} style={{ height: '100%' }}>
                          {l.status === 'COMPLETED' ? (
                            <div
                              style={{
                                height: '100%',
                                backgroundColor: 'white',
                                textAlign: 'center',
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                setPreview(images)
                              }}
                            >
                              <ImageItem images={images} />
                            </div>
                          ) : (
                            <Row
                              align={'middle'}
                              justify={'center'}
                              style={{
                                height: '100%',
                                backgroundColor: 'white'
                              }}
                            >
                              <Col
                                span={24}
                                style={{
                                  height: '47%',
                                  fontSize: 'large',
                                  fontWeight: 'bold',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'end'
                                }}
                              >
                                Your image will be ready in
                              </Col>
                              <Col
                                span={24}
                                style={{
                                  height: '6%',
                                  fontSize: 'large',
                                  fontWeight: 'bold',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                {l.estimatedTime}
                              </Col>
                              <Col
                                span={24}
                                style={{
                                  height: '47%',
                                  fontSize: 'large',
                                  fontWeight: 'bold',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'start'
                                }}
                              >
                                seconds
                              </Col>
                            </Row>
                          )}
                        </div>
                      )
                    })}
                  </Carousel>
                  <RightOutlined
                    style={{
                      position: 'absolute',
                      right: '5px',
                      top: '50%',
                      zIndex: '5',
                      fontSize: 'x-large',
                      cursor: 'pointer'
                    }}
                    onClick={() => carouselRef.current.next()}
                  />
                </Col>
                <Col
                  md={8}
                  sm={0}
                  xs={0}
                  style={{
                    height: '100%',
                    alignItems: 'top',
                    justifyContent: 'start',
                    backgroundColor: '#fcd895',
                    position: 'relative'
                  }}
                >
                  <PhotoInfo
                    photo={list[selectImg]?.sourceImageUri}
                    theme={themes.find(t => t.key === list[selectImg]?.theme)}
                    openProduct={setOpenProduct}
                    phoneName={
                      productTypes.find(p => p.id === product)?.displayName
                    }
                    price={productTypes.find(p => p.id === product)?.price}
                    format={shop.currencyFormats.moneyWithCurrencyFormat}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '20px',
                      width: '100%',
                      textAlign: 'center'
                    }}
                  >
                    {list[selectImg]?.status === 'COMPLETED' && (
                      <>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size={'large'}
                          style={{
                            textAlign: 'center',
                            backgroundColor: '#fb4343',
                            width: '90%',
                            color: 'white',
                            fontWeight: 'bold',
                            borderRadius: '0',
                            boxShadow:
                              '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                            padding: '7px 7px'
                          }}
                          onClick={() => {
                            if (!product) setOpenProduct(true)
                            else addProduct()
                          }}
                        >
                          Add To Cart
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size={'large'}
                          style={{
                            textAlign: 'center',
                            backgroundColor: '#fb4343',
                            width: '90%',
                            color: 'white',
                            fontWeight: 'bold',
                            borderRadius: '0',
                            boxShadow:
                              '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                            padding: '7px 7px',
                            marginTop: '20px'
                          }}
                          onClick={() => {
                            if (!product) {
                              setOpenProduct(true)
                            } else {
                              addProduct()
                              link('/cart')
                            }
                          }}
                        >
                          Buy Now
                        </Button>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
      <div style={{ flex: '0 1 auto' }}>
        <Row>
          <Col md={0} sm={24} xs={24}>
            {list[selectImg]?.status === 'COMPLETED' && (
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: '10px'
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  size={'large'}
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#fb4343',
                    width: '30%',
                    color: 'white',
                    fontWeight: 'bold',
                    borderRadius: '0',
                    boxShadow:
                      '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                    padding: '7px 7px',
                    marginRight: '20px'
                  }}
                  onClick={() => {
                    if (!product) setOpenProduct(true)
                    else addProduct()
                  }}
                >
                  Add To Cart
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  size={'large'}
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#fb4343',
                    width: '30%',
                    color: 'white',
                    fontWeight: 'bold',
                    borderRadius: '0',
                    boxShadow:
                      '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                    padding: '7px 7px'
                  }}
                  onClick={() => {
                    if (!product) {
                      setOpenProduct(true)
                    } else {
                      addProduct()
                      link('/cart')
                    }
                  }}
                >
                  Buy Now
                </Button>
              </div>
            )}
            <PhotoMenu />
          </Col>
        </Row>
      </div>
      <Products isOpen={openProduct} close={() => setOpenProduct(false)} />
      <Modal
        open={Boolean(preview)}
        onCancel={() => setPreview(null)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        centered
      >
        <div
          style={{
            height: 'calc(90vh - var(--browser-address-bar, 0px))',
            textAlign: 'center',
            overflow: 'hidden'
          }}
        >
          <ImageItem images={preview} />
        </div>
      </Modal>
    </div>
  )
}

const PhotoInfo = ({ photo, theme, openProduct, phoneName, price, format }) => {
  return (
    <Row
      style={{
        height: 'fit-content',
        padding: '10px'
      }}
    >
      <Col md={24} sm={0} xs={0}>
        <p
          style={{
            fontSize: 'x-large',
            fontWeight: 'bold',
            textAlign: 'left'
          }}
        >
          Generate From
        </p>
      </Col>
      <Col
        md={12}
        sm={0}
        xs={0}
        style={{
          textAlign: 'center'
        }}
      >
        <div
          style={{
            borderRadius: '50%',
            width: '100px',
            height: '100px',
            position: 'relative',
            backgroundImage: `url(${photo})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            margin: 'auto'
          }}
        />
        <div
          style={{
            fontSize: 'small',
            fontWeight: 'bold',
            marginTop: '5px'
          }}
        >
          Photo
        </div>
      </Col>
      <Col
        md={0}
        sm={6}
        xs={6}
        style={{
          textAlign: 'center'
        }}
      >
        <div
          style={{
            borderRadius: '50%',
            width: '60px',
            height: '60px',
            position: 'relative',
            backgroundImage: `url(${photo})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            margin: 'auto'
          }}
        />
        <div
          style={{
            fontSize: 'small',
            fontWeight: 'bold',
            marginTop: '5px'
          }}
        >
          Photo
        </div>
      </Col>
      <Col
        md={12}
        sm={0}
        xs={0}
        style={{
          textAlign: 'center'
        }}
      >
        <div style={{ height: '100px', width: '100%' }}>
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden'
            }}
          >
            <div style={{ height: '250%', width: '250%' }}>
              <ImageItem
                images={[
                  { url: theme.bgImageUri },
                  { url: theme.subjectImageUri },
                  { url: theme.fgImageUri }
                ]}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: 'small',
            fontWeight: 'bold',
            marginTop: '5px'
          }}
        >
          Theme
        </div>
      </Col>
      <Col
        md={0}
        sm={6}
        xs={6}
        style={{
          textAlign: 'center'
        }}
      >
        <div style={{ height: '60px', width: '100%' }}>
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden'
            }}
          >
            <div style={{ height: '250%', width: '250%' }}>
              <ImageItem
                images={[
                  { url: theme.bgImageUri },
                  { url: theme.subjectImageUri },
                  { url: theme.fgImageUri }
                ]}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: 'small',
            fontWeight: 'bold',
            marginTop: '5px'
          }}
        >
          Theme
        </div>
      </Col>
      <Col md={24} sm={0} xs={0}>
        <p
          style={{
            fontSize: 'x-large',
            fontWeight: 'bold',
            textAlign: 'left'
          }}
        >
          Your Phone Case
        </p>
      </Col>
      <Col
        md={24}
        sm={12}
        xs={12}
        style={{ height: 'fit-content', cursor: 'pointer' }}
        onClick={() => {
          openProduct(true)
        }}
      >
        <div style={{ width: '100%', textAlign: 'center' }}>
          <div
            style={{
              textAlign: 'center',
              cursor: 'pointer',
              display: 'inline-block'
            }}
          >
            <div>
              <Phone style={{ height: '55px', width: '55px' }} />
            </div>
            <div
              style={{
                fontSize: 'small',
                fontWeight: 'bold',
                marginTop: '5px'
              }}
            >
              {phoneName ?? 'Choose Phone Case'}
              {price && (
                <>
                  <br />
                  Price: {format.replace('{{amount}}', price)}
                </>
              )}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

const enhancers = pipe(
  memo,
  connect(
    (state, props) => ({
      list: state.photo.list,
      themes: state.category.themes,
      productTypes: state.category.productTypes,
      themeResizer: state.category.themeResizer,
      shop: state.category.shop,
      product: state.cart.product
    }),
    { ...categoryActions, ...photoActions }
  )
)

export default enhancers(Gallery)
