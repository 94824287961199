import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import Layout from './Layout'
import Home from '../Components/Home'
import PhotoUpload from '../Components/Photo/upload'
import Theme from '../Components/Photo/theme'
import Generate from '../Components/Photo/generate'
import Gallery from '../Components/Photo/gallery'
import Cart from '../Components/Cart'

const RouteProvider = () => {
  return (
    <Routes>
      <Route path="/:lang" element={<Layout />}>
        <Route path="photo">
          <Route path="upload" element={<PhotoUpload />} />
          <Route path="theme" element={<Theme />} />
          <Route path="generate" element={<Generate />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="" element={<Navigate to="generate" />} />
          <Route path="*" element={<Navigate to="generate" />} />
        </Route>
        <Route path="cart" element={<Cart />} />
        <Route path="" element={<Navigate to="/en/photo" />} />
        <Route path="*" element={<Navigate to="/en/photo" />} />
      </Route>
      <Route path="*" element={<Navigate to="/en/photo" />} />
    </Routes>
  )
}

export default RouteProvider
