import { all, put, takeLeading } from 'redux-saga/effects'
import actions from './action'
import Request from '../../Utilities/API/request'
import generalAction from '../General/action'
import { ProcessType, MessageType } from '../../Utilities/Constant/general'

function* upload({ payload }) {
  try {
    yield put(
      generalAction.showMessage({
        content: 'Uploading, please wait...',
        type: MessageType.loading
      })
    )
    yield put(actions.processChange(ProcessType.Processing))
    const { photo, theme } = payload
    let id = photo.imageId
    let result
    if (!id) {
      const formData = new FormData()
      formData.append('image', photo.originFileObj)
      result = yield Request.post('image/upload')
        .useAuth()
        .header('Content-Type', 'multipart/form-data')
        .body(formData)
        .send()
      if (result.status === 200) {
        id = result.data.data.imageId
        yield put(actions.uploadSuccess(id))
      }
    }
    result = yield Request.post('phone-case/generate-image')
      .useAuth()
      .body({ theme, imageId: id })
      .send()
    if (result.status === 200) {
      yield put(actions.generateSuccess(result.data.data.estimatedTime))
      yield put(
        generalAction.showMessage({
          content: 'Upload Success',
          type: MessageType.success
        })
      )
      yield put(actions.processChange(ProcessType.Success))
    } else {
      yield put(actions.processChange(ProcessType.Failed))
    }
  } catch (error) {
    console.log(error)
    yield put(actions.processChange(ProcessType.Failed))
  }
}

function* getProgress() {
  try {
    const result = yield Request.post('phone-case/fetch-upload-progress')
      .body({
        thumbnail: {
          dimension: 'hero', // for in-house image resize usage
          maxWidth: '640', // for shopify image resize usage
          type: 'webp' // works on both in-house / shopify
        }
        // check https://docs.google.com/document/d/17JFg8TaDsXMY0VB2Cxfn7vYpeXFq65Rl3u2_9Dy2uUc/edit#heading=h.lnrwalhs8ewi for more info
      })
      .useAuth()
      .send()
    if (result.status === 200) {
      yield put(actions.getProgressSuccess(result.data.data.list))
    }
  } catch (error) {
    console.log(error)
  }
}

function* placeOrder({ payload }) {
  try {
    yield put(
      generalAction.showMessage({
        content: '訂單生成中, 請稍後',
        type: MessageType.loading
      })
    )
    const result = yield Request.post('order/place-order')
      .useAuth()
      .body(payload)
      .send()
    if (result.status === 200) {
      yield put(actions.placeOrderSuccess(result.data.data.orderNumber))
      yield put(
        generalAction.showMessage({
          content: '訂單已發送',
          type: MessageType.success
        })
      )
    }
  } catch (error) {
    console.log(error)
  }
}

export default function* root() {
  yield all([
    takeLeading(actions.upload.toString(), upload),
    takeLeading(actions.getProgress.toString(), getProgress),
    takeLeading(actions.placeOrder.toString(), placeOrder)
  ])
}
