import { createActions } from 'redux-actions'

export default createActions(
  'INITIALIZE',
  'INITIALIZE_DONE',
  'REFRESH_TOKEN',
  'REFRESH_TOKEN_SUCCESS',
  'REFRESH_TOKEN_FAIL',
  'SHOW_MESSAGE',
  {
    prefix: 'GENERAL'
  }
)
