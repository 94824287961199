import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'

const bindAction = (action, dispatch) => {
  var result = bindActionCreators(action, dispatch)
  result.toString = () => action.toString()
  return result
}

export default function useActions(actions) {
  const dispatch = useDispatch()
  return useMemo(() => {
    if (Array.isArray(actions)) {
      return actions.map(a => bindAction(a, dispatch))
    }
    return bindAction(actions, dispatch)
  }, [dispatch, actions])
}
