import { all, put, takeLeading } from 'redux-saga/effects'
import actions from './action'
import Request from '../../Utilities/API/request'

function* getMeta() {
  try {
    const result = yield Request.post('phone-case/fetch-meta')
      .body({
        thumbnail: {
          dimension: 'hero',
          maxWidth: '640',
          type: 'webp'
        }
      })
      .useAuth()
      .send()
    if (result.status === 200) {
      yield put(actions.getMetaSuccess(result.data.data))
    }
  } catch (error) {
    console.log(error)
  }
}

export default function* root() {
  yield all([takeLeading(actions.getMeta.toString(), getMeta)])
}
