import axios from 'axios'
import { getBaseData } from './userStorage'

export default class Request {
  static get = url => new Request(url)
  static post = url => new Request(url, 'POST')
  static patch = url => new Request(url, 'PATCH')
  static put = url => new Request(url, 'PUT')
  static del = url => new Request(url, 'DELETE')

  constructor(url, method = 'GET') {
    this._url = '/api/v1/' + url
    this._isAuth = false
    this._method = method
    this._headers = {}
    this._headers['Access-Control-Allow-Origin'] = '*'
    this._urlParams = {}
    this._bodyData = undefined
    this._file = false
  }

  header(key, value) {
    if (value) this._headers[key] = value
    return this
  }

  urlParam(key, value) {
    if (value) this._urlParams[key] = value
    return this
  }

  urlParams(data) {
    if (data) Object.keys(data).forEach(key => this.urlParam(key, data[key]))
    return this
  }

  body(data) {
    if (data) this._bodyData = data
    return this
  }

  useAuth() {
    const deviceToken = getBaseData()
    this._headers['X-DEVICE-ID'] = `${deviceToken}`
    this._isAuth = true
    return this
  }

  getFile() {
    this._file = true
    return this
  }

  send() {
    const config = {
      url: this._url,
      method: this._method,
      headers: this._headers,
      params: this._urlParams,
      data: this._bodyData,
      skipAuthRefresh: !this._isAuth
    }
    if (this._file) {
      config.responseType = 'blob'
    }
    return axios(config)
      .then(response => {
        return response
      })
      .catch(err => {
        throw err
      })
  }
}
